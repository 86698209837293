<template>
  <div>
    <div class="flex-1 flex flex-col p-8">
      <img class="w-32 h-32 flex-shrink-0 mx-auto rounded-full object-cover" :src="user.profileImage" alt="" />
      <h3 class="mt-6 text-gray-900 text-lg font-medium">{{ returnName }}</h3>
      <dl class="mt-1 flex-grow flex flex-col justify-between">
        <dt class="sr-only">Title</dt>
        <dd class="text-gray-500 text-base">{{ user.guideType + ' Guide' }}</dd>
        <dt class="sr-only">Capacity</dt>
        <dd class="text-gray-500 text-base">{{ user.capacity }}</dd>
        <dt class="sr-only">Role</dt>
        <dd class="mt-3">
          <span class="px-2 py-1 text-white text-xs font-medium bg-rag-green bg-opacity-80 rounded-full">{{ user.location }}</span>
        </dd>
      </dl>
    </div>
    <div>
      <div class="flex divide-x divide-gray-200">
        <div class="-ml-px w-0 flex-1 flex">
          <div
            class="relative w-0 flex-1 inline-flex items-center justify-center py-2 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
          >
            <span>View more</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuideCard',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    returnName() {
      let name = '';
      if (this.user.firstName && this.user.firstName !== '') {
        name = this.user.firstName;
      } else {
        name = this.user.fullName.split(' ')[0];
      }
      return name;
    },
  },
};
</script>

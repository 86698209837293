<template>
  <div>
    <ais-instant-search
      id="algolia-instant-search-demo"
      :search-client="searchClient"
      index-name="guide_users"
      :stalled-search-delay="300"
      :initial-ui-state="initialState"
    >
      <vs-popup v-show="!isAdmin && showJobDialog" title="No active jobs!" :button-close-hidden="true">
        <p> You need to have an active job post to be able to see a guide's full profile. </p>
        <br />
        <vs-button class="mr-3" @click="postJob()">Post job</vs-button>
        <vs-button type="border" color="danger" @click="showJobDialog = false">Cancel</vs-button>
      </vs-popup>
      <!-- v-if="isSidebarActive"
        v-model="isSidebarActiveLocal" -->

      <portal to="rag-sidebar">
        <div>
          <profile-user-profile ref="userProfile" :user="user" :show-profile="true" :side="true"></profile-user-profile>
          <portal to="rag-sidebar-footer">
            <div id="inviteToJobBar" class="py-4 px-5 sm:px-6 lg:px-9">
              <!-- <div class="close"> <feather-icon icon="XIcon" @click.prevent="close"></feather-icon> </div> -->
              <div class="sm:flex">
                <div class="mb-3 w-full sm:mb-0 sm:w-8/12 sm:mr-3">
                  <multiselect
                    v-model="selectedJob"
                    deselect-label=""
                    track-by="jobTitle"
                    select-label=""
                    label="jobTitle"
                    open-direction="above"
                    placeholder="Select a job"
                    :show-label="false"
                    :options="jobs"
                    :searchable="true"
                    :allow-empty="false"
                    :multiple="false"
                    @select="jobSelected"
                    @remove="removeJob"
                  >
                  </multiselect>
                </div>
                <vs-button v-if="!hasApp" class="sm:mt-0 w-full sm:w-1/3" @click="inviteToJob">Invite to job</vs-button>
                <vs-button v-else color="grey" class="sm:mt-0 w-full sm:w-1/3" disabled>Already applied</vs-button>

                <!-- <div class="w-full sm:flex justify-between sm:justify-evenly">
            <vs-button v-if="!hasApp" class="vx-col lg:w-3/12" @click="inviteToJob">Invite to job</vs-button>
            <vs-button class="vx-col lg:w-2/12 self-end" color="danger" select @click="close">Close</vs-button>
          </div> -->
              </div>
            </div>
          </portal>
        </div>
      </portal>

      <ais-configure :hits-per-page.camel="15" />
      <!-- <ais-configure :facets="['guideType', 'capacity']" /> -->
      <ais-configure :filters="algoliaFilters" />

      <div class="sticky algolia-header mb-4">
        <div class="flex md:items-end items-center justify-between flex-wrap">
          <!-- TOGGLE SIDEBAR BUTTON -->
          <!-- <feather-icon class="inline-flex lg:hidden cursor-pointer mr-4" icon="MenuIcon" @click.stop="toggleFilterSidebar" /> -->

          <div class="flex justify-between items-end flex-grow">
            <!-- Stats -->

            <ais-toggle-refinement attribute="active" label="string" :on="true" :off="true" style="display: none" />
          </div>
        </div>
      </div>

      <div id="algolia-content-container" class="relative clearfix">
        <!-- TODO HERE -->
        <vs-sidebar
          v-model="isFilterSidebarActive"
          class="items-no-padding vs-sidebar-rounded background-absolute"
          parent="#algolia-content-container"
          :click-not-close="clickNotClose"
          :hidden-background="clickNotClose"
        >
          <vs-card class="p-6 mb-0 shadow-none filter-container lg:border-solid lg:border-2 lg:border-gray-200">
            <vs-icon size="small" class="absolute right-3 top-3 lg:hidden" icon="close" @click="isFilterSidebarActive = false"></vs-icon>
            <h6 class="font-bold mb-4">Guide Type</h6>
            <ais-hierarchical-menu :attributes="algoliaCategories">
              <div slot-scope="{ items, refine }">
                <ul>
                  <li v-for="item in items" :key="item.value" @click="refine(item.value)">
                    <div class="flex items-center justify-between py-1 text-base">
                      <div class="flex items-center cursor-pointer">
                        <feather-icon icon="CircleIcon" :svg-classes="[{ 'text-primary fill-current': item.isRefined }, 'h-5 w-5']" />
                        <span class="ml-2" :class="{ 'text-primary': item.isRefined }">{{ item.label }}</span>
                      </div>
                      <span>{{ item.count }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </ais-hierarchical-menu>

            <!-- <vs-divider />
          <h6 class="font-bold mb-3">Salary Expectations</h6>
          <ais-range-input attribute="minSalary">
            <div slot-scope="{ currentRefinement, range, refine }">
              <vs-slider
                v-model="value1"
                class="algolia-price-slider"
                text-fixed="R"
                step="500"
                :min="range.min"
                :max="range.max"
                :value="toValue(currentRefinement, range)"
                @input="refine({ min: $event[0], max: $event[1] })"
              />
            </div>
          </ais-range-input> -->
            <vs-divider />
            <h6 class="font-bold mb-4">Languages</h6>
            <ais-refinement-list attribute="languagesNew.language" show-more :show-more-limit="100">
              <div slot-scope="{ items, isFromSearch, refine }">
                <ul>
                  <li v-if="isFromSearch && !items.length">No results.</li>
                  <li
                    v-for="item in items"
                    :key="item.value"
                    class="mb-2 flex items-center justify-between text-base"
                    @click="refine(item.value)"
                  >
                    <vs-checkbox v-model="item.isRefined" class="ml-0">{{ item.label }}</vs-checkbox>
                    <span>{{ item.count }}</span>
                  </li>
                </ul>
                <!--               <button :disabled="!canToggleShowMore" @click="toggleShowMore">
                {{ !isShowingMore ? 'Show more' : 'Show less' }}
              </button> -->
              </div>
            </ais-refinement-list>

            <vs-divider />
            <h6 class="font-bold mb-4">Locations</h6>
            <ais-refinement-list attribute="location">
              <div slot-scope="{ items, isFromSearch, refine }">
                <ul>
                  <li v-if="isFromSearch && !items.length">No results.</li>
                  <li
                    v-for="item in items"
                    :key="item.value"
                    class="mb-2 flex items-center justify-between text-base"
                    @click="refine(item.value), setLocation(item)"
                  >
                    <vs-checkbox v-model="item.isRefined" class="ml-0">{{ item.label }}</vs-checkbox>
                    <span>{{ item.count }}</span>
                  </li>
                </ul>
              </div>
            </ais-refinement-list>
            <vs-divider />
            <h6 class="font-bold mb-4">Region</h6>
            <ais-refinement-list attribute="provinces" show-more :show-more-limit="100">
              <div slot-scope="{ items, isFromSearch, refine }">
                <ul>
                  <li v-if="isFromSearch && !items.length">No results.</li>
                  <li
                    v-for="item in items"
                    :key="item.value"
                    class="mb-2 flex items-center justify-between text-base"
                    @click="refine(item.value)"
                  >
                    <vs-checkbox v-model="item.isRefined" class="ml-0">{{ item.label }}</vs-checkbox>
                    <span>{{ item.count }}</span>
                  </li>
                </ul>
                <!--               <button :disabled="!canToggleShowMore" @click="toggleShowMore">
                {{ !isShowingMore ? 'Show more' : 'Show less' }}
              </button> -->
              </div>
            </ais-refinement-list>
            <vs-divider />
            <h6 class="font-bold mb-4">Qualifications</h6>
            <!-- <ais-refinement-list attribute="documents.qualType"> -->
            <ais-refinement-list attribute="documents.category">
              <div slot-scope="{ items, isFromSearch, refine }">
                <ul>
                  <li v-if="isFromSearch && !items.length">No results.</li>
                  <li
                    v-for="item in items"
                    :key="item.value"
                    class="mb-2 flex items-center justify-between text-base"
                    @click="refine(item.value)"
                  >
                    <vs-checkbox v-model="item.isRefined" class="ml-0">{{ item.label }}</vs-checkbox>
                    <span>{{ item.count }}</span>
                  </li>
                </ul>
              </div>
            </ais-refinement-list>

            <vs-divider />
            <h6 class="font-bold mb-4">Experience</h6>
            <ais-refinement-list attribute="exp" searchable show-more :show-more-limit="100">
              <div slot-scope="{ items, isFromSearch, refine }">
                <ul>
                  <!-- <input @input="searchForItems($event.currentTarget.value)" /> -->
                  <li v-if="isFromSearch && !items.length">No results.</li>
                  <li
                    v-for="item in items"
                    :key="item.value"
                    class="mb-2 flex items-center justify-between text-base"
                    @click="refine(item.value)"
                  >
                    <vs-checkbox v-model="item.isRefined" class="ml-0">{{ item.label }} years</vs-checkbox>
                    <span>{{ item.count }}</span>
                  </li>
                </ul>
              </div>
            </ais-refinement-list>
            <vs-divider />
            <ais-clear-refinements class="flex justify-center">
              <vs-button slot-scope="{ canRefine, refine }" class="w-full" :disabled="!canRefine" @click.prevent="refine"
                >Clear Filters</vs-button
              >
            </ais-clear-refinements>
          </vs-card>
        </vs-sidebar>
        <div :class="{ 'sidebar-spacer-with-margin': clickNotClose }">
          <ais-search-box show-loading-indicator :search-client="searchClient">
            <div slot-scope="{ currentRefinement, isSearchStalled, refine }">
              <div class="relative mb-8 border-solid border-2 border-gray-200 rounded-lg">
                <app-debounced-search-box ref="searchbox" :delay="200" />
                <span :hidden="!isSearchStalled">Loading...</span>

                <div v-show="!currentRefinement" slot="submit-icon" class="absolute top-0 right-0 py-4 px-6">
                  <feather-icon icon="SearchIcon" svg-classes="h-6 w-6" />
                </div>

                <div v-show="currentRefinement" slot="reset-icon" class="absolute top-0 right-0 py-4 px-6" @click="clearInput">
                  <feather-icon icon="XIcon" svg-classes="h-6 w-6 cursor-pointer" @click="refine('')" />
                </div>
              </div>
            </div>
            <ais-clear-refinements />
          </ais-search-box>
          <vs-button class="w-full lg:w-auto mb-5 lg:hidden" color="primary" @click.stop="toggleFilterSidebar">Show Filters</vs-button>
          <ais-hits>
            <div slot-scope="{ items }">
              <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xxl:grid-cols-4">
                <li v-for="item in items" :key="item.email" @click="moreInfo(item)">
                  <vx-card
                    class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 cursor-pointer border-solid border-2 border-gray-200"
                  >
                    <GuideCard :user="item" />
                  </vx-card>
                </li>
              </ul>
            </div>
          </ais-hits>
          <ais-pagination>
            <div slot-scope="{ currentRefinement, nbPages, refine }">
              <vs-pagination
                :total="nbPages"
                :max="7"
                :value="currentRefinement + 1"
                @input="
                  (val) => {
                    refine(val - 1);
                  }
                "
              />
            </div>
          </ais-pagination>
        </div>
      </div>
    </ais-instant-search>
    <GuideBoardTour />
  </div>
</template>
<!--eslint-disable vue/custom-event-name-casing-->
<!--eslint-disable vue/no-unused-vars-->

<script>
import algoliasearch from 'algoliasearch/lite';
import AppDebouncedSearchBox from '../components/DebouncedSearchBox.vue';
import ProfileUserProfile from '../components/guide-profile-components/ProfileUserProfile.vue';
import GuideBoardTour from '../components/tours/GuideBoardTour.vue';
import GuideCard from '../components/guide-board-components/GuideCard.vue';

export default {
  components: {
    // ItemGridView: () => import('../apps/eCommerce/components/ItemGridView.vue'),
    AppDebouncedSearchBox,
    GuideBoardTour,
    GuideCard,
    ProfileUserProfile,
  },
  data() {
    return {
      initialState: {
        indexName: {
          query: 'phone',
          page: 5,
        },
      },
      searchClient: algoliasearch(process.env.VUE_APP_ALGOLIA_API_KEY, process.env.VUE_APP_ALGOLIA_SECRET),
      selectedJob: this.$store.state['jobsModule'].data[this.$route.query.jobId] ?? { jobTitle: 'Select job' },
      isSidebarActive: false,
      // Filter Sidebar
      isFilterSidebarActive: true,
      clickNotClose: true,
      windowWidth: window.innerWidth,
      hasApp: false,
      currentItemView: 'item-grid-view',
      algoliaCategories: ['guideType'],
      specAreas: ['specArea.Airport Transfer', 'specArea.Cooking'],
      user: {},
      isShowingMore: false,
      canToggleShowMore: false,
      showJobDialog: false,
      value1: [0, 20000],
      location: '',
    };
  },
  computed: {
    algoliaFilters() {
      if (!this.getJobs || Object.keys(this.getJobs).length === 0) return '';
      const { guideType, secondaryGuideType, jobType } = this.$route.query;
      if (guideType && secondaryGuideType && jobType)
        return `(guideType:"${guideType}" OR guideType:"${secondaryGuideType}" OR secondaryGuideType:"${guideType}" OR secondaryGuideType:"${secondaryGuideType}") AND capacity:"${jobType}"`;
      let query = '';
      const jobs = Object.values(this.getJobs).map((job) => job);
      const jobTypesFiltered = [...new Set(jobs.map((job) => job.jobType))];
      const capacityConditions = jobTypesFiltered.map((type) => `capacity:"${type}"`).join(' OR ');
      query = capacityConditions.length === 1 ? capacityConditions : `(${capacityConditions}) AND `;

      jobs.forEach((job, index) => {
        if (job.secondaryGuideType) {
          query +=
            (index > 0 ? ' OR ' : '') +
            `(guideType:"${job.guideType}" OR guideType:"${job.secondaryGuideType}" OR secondaryGuideType:"${job.guideType}" OR secondaryGuideType:"${job.secondaryGuideType}")`;
        } else {
          query += (index > 0 ? ' OR ' : '') + `guideType:"${job.guideType}" OR secondaryGuideType:"${job.guideType}"`;
        }
      });
      return query;
    },
    isAdmin() {
      return this.$store.getters['auth/isFirebaseAdmin'];
    },
    apps() {
      return this.$store.state.applicationsModule.idJobUser;
    },
    toValue() {
      return (value, range) => [value.min !== null ? value.min : range.min, value.max !== null ? value.max : range.max];
    },
    filters() {
      return this.$store.getters['filtersModule/getFilters'];
    },
    getJobs() {
      return this.$store.state['jobsModule'].data;
    },
    jobs() {
      return Object.values(this.getJobs).sort(function (a, b) {
        let timeX = a.updatedAt;
        let timeY = b.updatedAt;
        return timeY - timeX;
      });
    },
    // isSidebarActiveLocal: {
    //   get() {
    //     return this.isSidebarActive;
    //   },
    //   set(val) {
    //     console.log(val);
    //     if (!val) {
    //       this.$emit('closeSidebar');
    //     }
    //     if (val && !this.isSidebarActive) {
    //       this.isSidebarActive = true;
    //     }
    //     if (!val && this.isSidebarActive) {
    //       this.isSidebarActive = false;
    //     }
    //   },
    // },
  },
  // watch: {
  //   isSidebarActive() {
  //     console.log(this.isSidebarActive);
  //   },
  // },
  destroyed: function () {
    document.removeEventListener('keyup', this.myMethod);
  },
  created() {
    document.addEventListener('keyup', this.myMethod);
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleWindowResize);
    });
    this.setSidebarWidth();
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    getProfileStatus(user) {
      if (!user) {
        return false;
      }
      if (!user.profileDeactivated) {
        if (user.active === true) {
          return true;
        } else {
          return false;
        }
      }
      if (user.profileDeactivated) {
        if (user.profileDeactivated === true || user.active === false) {
          return false;
        } else {
          return true;
        }
      }
    },

    setLocation(item, isFromSearch) {
      console.log(item, isFromSearch);
      if (item.isRefined) {
        this.location = item.value;
      } else {
        this.location = '';
      }
    },
    clearInput() {
      this.$refs.searchbox.clearQuery();
    },

    postJob() {
      this.$router.push({ name: 'Jobs' });
      this.showJobDialog = false;
    },
    myMethod(event) {
      if (event.keyCode === 27) {
        this.$store.dispatch('toggleSidebar', false);
      }
    },
    async moreInfo(user) {
      if (!this.$route.query.jobId) this.selectedJob = { jobTitle: 'Select job' };
      this.hasApp = false;
      await this.$store.dispatch('jobsModule/setJobList');
      if (this.isAdmin) {
        this.user = user;
        this.$store.dispatch('toggleSidebar', true);
      }
      if (this.$store.getters['jobsModule/getJobTotal'] > 0) {
        this.user = user;
        this.$store.dispatch('toggleSidebar', true);
      } else {
        this.showJobDialog = true;
      }
    },
    removeJob() {
      this.hasApp = false;
    },
    toggleShowMore() {},

    jobSelected(job) {
      this.hasApp = false;
      if (Object.keys(this.apps).length !== 0) {
        if (this.apps[job.jobId]) {
          if (this.apps[job.jobId].includes(this.user.userId)) {
            this.hasApp = true;
          } else {
            this.hasApp = false;
          }
        }
      }
    },
    async inviteToJob() {
      console.log(this.selectedJob);
      if (this.selectedJob.jobTitle !== 'Select job') {
        await this.$store.dispatch('applicationsModule/createApplication', {
          user: this.user,
          job: this.selectedJob,
          empId: this.$store.state.employerModule.data.employerId,
        });
        this.selectedJob = { jobTitle: 'Select job' };
        this.$store.dispatch('toggleSidebar', false);
        this.$vs.notify({
          title: 'Success!',
          text: `An invitation has been sent to ${this.user.firstName ? this.user.firstName : this.user.fullName}`,
          color: 'success',
        });
      } else {
        this.$vs.notify({
          title: 'No job selected',
          text: `Please select a job.`,
          color: 'danger',
        });
      }
    },
    formatData(data) {
      // formats data received from API
      let formattedData = Object.values(data).map(function (key) {
        const fields = key;
        let obj = {};
        for (const key of Object.keys(fields)) {
          obj[key] = Number(fields[key]) || Number(fields[key]) || fields[key];
        }

        return obj;
      });

      return formattedData;
    },
    arrFilters(filter) {
      let arr = [];
      for (const key in filter) {
        arr.push(filter[key]);
      }
      return arr;
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 992) {
        this.isFilterSidebarActive = this.clickNotClose = false;
      } else {
        this.isFilterSidebarActive = this.clickNotClose = true;
      }
    },

    // GRID VIEW - ACTIONS
    toggleFilterSidebar() {
      if (this.clickNotClose) return;
      this.isFilterSidebarActive = !this.isFilterSidebarActive;
    },
    toggleItemInWishList(item) {
      this.$store.dispatch('eCommerce/toggleItemInWishList', item);
    },
    additemInCart(item) {
      this.$store.dispatch('eCommerce/additemInCart', item);
    },
  },
};
</script>

<style lang="scss">
.add-new-data-sidebar {
  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 800px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.multiselect__option--highlight {
  background: #adca63;
}
#algolia-instant-search-demo {
  .algolia-header {
    .algolia-filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #algolia-content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .algolia-search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .algolia-price-slider {
    min-width: unset;
  }

  .ais-RefinementList input {
    width: 100%;
    padding: 5px;
    margin: 10px 0px;
  }
  .ais-RefinementList button {
    width: 100%;
    background: none;
    border: none;
  }
  .ais-RefinementList button:hover {
    cursor: pointer;
  }

  /*   .item-list-view {
    .algolia-result-img {
    }
  } */

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.vx-card .vx-card__collapsible-content.vs-con-loading__container {
  height: 100%;
  padding-bottom: 25px;
}

.item-view-primary-action-btn {
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.theme-dark {
  #algolia-instant-search-demo {
    #algolia-content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }
    .vs-sidebar--items {
      border-radius: 0.5rem;
      overflow: hidden;
    }
  }
}

@media (max-width: 992px) {
  #algolia-content-container {
    .vs-sidebar {
      position: absolute !important;
      float: none !important;
    }
  }
}
// .vs-sidebar--footer {
//   border: none !important;
// }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
